import React from 'react';
import {ROOT_PATH, authRole} from '../../shared/constants/AppConst';

export const DroneConfig = [
  {
    auth: authRole.companyOwner,
    routes: [
      {
        path: `${ROOT_PATH}/list-drone`,
        component: React.lazy(() => import('./DroneList/Index')),
      },
    ],
  },
  {
    auth: authRole.pilot,
    routes: [
      {
        path: `${ROOT_PATH}/list-drone-pilot`,
        component: React.lazy(() => import('./DroneList/Index')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/drone-details/:id`,
        component: React.lazy(() => import('./DroneDetail/Index')),
      },
    ],
  },
];
