import React from 'react';
import moment from 'moment';
import {LIST_GENDER} from './LocalConstants';
import {format, parse} from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import {IconCheck, IconClose} from 'common/component/Icon';
import {getIn} from 'formik';

export const getDate = (value, format = 'YYYY年MM月DD日') => {
  let date = null;
  if (value) {
    if (moment(value).isValid()) {
      date = new Date(value);

      if (isNaN(date)) {
        date = moment(value).toDate();
      }
    } else if (moment(value, 'YYYY年MM月DD日').isValid()) {
      date = moment(value, 'YYYY年MM月DD日').toDate();
    }
  }

  return date ? moment(date).format(format) : '';
};

export const parseJapaneseDate = (japaneseDateString) => {
  if (japaneseDateString !== null && japaneseDateString !== undefined) {
    const [year, month, day] = japaneseDateString
      .replace(/[年月]/g, '/')
      .replace('日', '')
      .split('/');
    return new Date(`${year}/${month}/${day}`);
  }
  return new Date();
};

export const formatToJapaneseDate = (value) => {
  let date = null;
  if (value) {
    if (moment(value).isValid()) {
      date = new Date(value);

      if (isNaN(date)) {
        date = moment(value).toDate();
      }
    } else if (moment(value, 'YYYY年MM月DD日').isValid()) {
      date = moment(value, 'YYYY年MM月DD日').toDate();
    }
  }

  if (date instanceof Date) {
    return date.toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  return '';
};

export const getTimeOnly = (date) => {
  if (date instanceof Date) {
    return format(date, 'HH:mm', {
      locale: jaLocale,
    });
  }
  return '';
};

export const parseTimeOnly = (timeString) => {
  return parse(timeString, 'HH:mm', new Date());
};

export const getGender = (value) => {
  return value ? LIST_GENDER.find((item) => item.value === value)?.name : '';
};

export function downloadFile(file, fileName, type) {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  if (type) {
    link.href = window.URL.createObjectURL(new Blob([file], {type}));
  } else {
    link.href = window.URL.createObjectURL(new Blob([file]));
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getItemActive = (name) => (value) => {
  if (getIn(value, name) == 1) {
    return <IconCheck />;
  }

  return <IconClose />;
};

export const convertStringToTotalHours = (value) => {
  if (typeof value === 'string') {
    const listMinute = value.split(':');
    if (listMinute.length !== 2) {
      return value;
    }

    const minute = Number(listMinute[1] || 0);
    const hour = Number(listMinute[0] || 0) + minute / 60;
    return !isNaN(hour) ? Number(hour.toFixed(2)) : 0;
  }
  return value;
};
