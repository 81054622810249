import React from 'react';
import {ROOT_PATH, authRole} from '../../shared/constants/AppConst';
import {exportExcelLog, exportOutputLogMobile} from './service';
import {IconExcel, IconPdf} from 'common/component/Icon';

export const ExportLogConfig = [
  {
    auth: authRole.pilot,
    routes: [
      {
        path: `${ROOT_PATH}/export-excel-log`,
        component: React.lazy(() => import('./ExportExcel.jsx')),
        title: 'exportLog.excel',
        titleButton: 'common.toExcel',
        api: exportExcelLog,
        Icon: IconExcel,
      },
      {
        path: `${ROOT_PATH}/export-pdf-log`,
        component: React.lazy(() => import('./ExportExcel.jsx')),
        title: 'exportLog.pdf',
        titleButton: 'common.toPdf',
        api: exportOutputLogMobile,
        Icon: IconPdf,
      },
    ],
  },
];
