import axios from 'axios';
import {useDispatch} from 'react-redux';
import {SIGNOUT_AUTH_SUCCESS} from 'shared/constants/ActionTypes';
import {API_ENPOINT} from 'shared/constants/AppConst';

const jwtAxios = axios.create({
  baseURL: API_ENPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
