import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import {
  LIST_OUTPUT_LOG_TYPE,
  OutputLogType,
} from 'common/constants/LocalConstants';
import {downloadFile, getDate} from 'common/constants/LocalFunction';
import {toast} from 'react-toastify';

const FLIGHT_LOG = 'flight-log';
const CHECKING_LOG = 'checking-log';
const MAINTENANCE_LOG = 'maintenance-log';
const MOBILE_FLIGHT_LOG = 'mobile/flight-log';

export const exportOutputLogMobile = async (obj) => {
  try {
    const res = await jwtAxios.post(
      MOBILE_FLIGHT_LOG + '/export-output-log',
      obj,
      {
        responseType: 'blob',
      },
    );

    if (res.data) {
      let fileName = LIST_OUTPUT_LOG_TYPE.find(
        (item) => item.value === obj.reportType,
      )?.fileName;
      fileName += '_' + getDate(new Date(), 'YYYYMMDD');
      downloadFile(res.data, fileName + '.pdf');
    }
  } catch (e) {
    toast.error('エラーが発生しました');
    console.error(e);
  }
};

export const exportExcelLog = async (obj) => {
  try {
    let path = 'mobile/';
    if (
      obj.reportType === OutputLogType.JOURNEY_LOG ||
      obj.reportType === OutputLogType.PILOT_LOG
    ) {
      path += FLIGHT_LOG;
    }

    if (obj.reportType === OutputLogType.CHECKING_LOG) {
      path += CHECKING_LOG;
    }

    if (obj.reportType === OutputLogType.MAINTENANCE_LOG) {
      path += MAINTENANCE_LOG;
    }

    const res = await jwtAxios.post(path + '/export-excel', obj, {
      responseType: 'blob',
    });

    if (res.data) {
      let fileName = LIST_OUTPUT_LOG_TYPE.find(
        (item) => item.value === obj.reportType,
      )?.fileName;
      fileName += '_' + getDate(new Date(), 'YYYYMMDD');
      downloadFile(res.data, fileName + '.xlsx');
    }
  } catch (e) {
    toast.error('エラーが発生しました');
    console.error(e);
  }
};

export const getOutputLogMobile = (obj) =>
  jwtAxios.post('mobile/' + FLIGHT_LOG + '/output-log', obj);
