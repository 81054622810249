import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
import {ROOT_PATH} from '../../shared/constants/AppConst';
export const dashBoardConfigs = [
  {
    // auth: authRole.pilot,
    routes: [
      {
        path: `${ROOT_PATH}/dashboard`,
        component: React.lazy(() => import('./UAV')),
      },
    ],
  },
];
