import React from 'react';
import {ROOT_PATH} from 'shared/constants/AppConst';

export const authRouteConfig = [
  {
    routes: [
      {
        path: `${ROOT_PATH}/signin`,
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/signup`,
        component: React.lazy(() => import('./Signup/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/confirm-signup`,
        component: React.lazy(() => import('./ConfirmSignupAwsCognito')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/reset-password`,
        component: React.lazy(() => import('./ResetPasswordAwsCognito')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/forget-password`,
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/error-pages/error-404`,
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
