import React from 'react';
import {ROOT_PATH, authRole} from '../../shared/constants/AppConst';

export const managementConfig = [
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/airframe`,
        component: React.lazy(() => import('./airframe/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/drone-types`,
        component: React.lazy(() => import('./DroneTypes/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/manufacturer`,
        component: React.lazy(() => import('./manufacturer/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/company`,
        component: React.lazy(() => import('./Company/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/no-fly-zone-method`,
        component: React.lazy(() => import('./NoFlyZoneMethod/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/flight-method`,
        component: React.lazy(() => import('./FlightMethod/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/flight-purpose`,
        component: React.lazy(() => import('./FlightPurpose/Index')),
      },
    ],
  },
  {
    auth: authRole.admin,
    routes: [
      {
        path: `${ROOT_PATH}/management/global-property`,
        component: React.lazy(() => import('./GlobalProperty/Index')),
      },
    ],
  },
];
