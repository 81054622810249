import React from 'react';
import {ROOT_PATH, authRole} from '../../shared/constants/AppConst';

export const PilotConfig = [
  {
    auth: authRole.companyOwner,
    routes: [
      {
        path: `${ROOT_PATH}/list-pilot`,
        component: React.lazy(() => import('./ListPilot/Index')),
      },
      {
        path: `${ROOT_PATH}/detail-pilot/:id`,
        component: React.lazy(() => import('./DetailsPilot/Index')),
      },
      {
        path: `${ROOT_PATH}/pilot-form`,
        component: React.lazy(() => import('./PilotForm/Index')),
      },
    ],
  },
];
