import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {ROOT_PATH, initialUrl} from '../shared/constants/AppConst';
import {managementConfig} from './management';
import {PilotConfig} from './Pilot';
import {ProfileConfig} from './Profile';
import {UserConfig} from './User';
import {DroneConfig} from './Drone';
import {OutputLogConfig} from './OuputLog';
import {NotificationsConfig} from './Notifications';
import {ExportLogConfig} from './ExportLog';

const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...managementConfig,
  ...PilotConfig,
  ...ProfileConfig,
  ...UserConfig,
  ...DroneConfig,
  ...OutputLogConfig,
  ...NotificationsConfig,
  ...ExportLogConfig,
  ...errorPagesConfigs,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    path: ROOT_PATH + '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to={`${ROOT_PATH}/error-pages/error-404`} />,
  },
];

export default routes;
